/** @jsx jsx */
import { jsx } from 'theme-ui'
import ReserveService from './reserveService'
import Search from './Search'
import PopularProjects from './PopularProjects'
import Foot from './Foot'
import Register from './Register'
import RegisterVoice from './RegisterVoice'

const Main = () => {
  return (
    <div>
      <ReserveService/>
      <Search />
      <Foot />
      <PopularProjects />
      <RegisterVoice />
      <Register />
    </div>
  )
}

export default Main
