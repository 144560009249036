/** @jsx jsx */
import { jsx } from 'theme-ui'

const Search = () => (
  <div
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '50px 0',
      color: 'white',
      fontFamily: 'Noto Sans JP, sans-serif',
    }}
  >
    <div
      sx={{
        position: 'relative',
        fontWeight: 600,
        display: 'flex',
        alignItems: ['flex-start', 'center'],
        justifyContent: 'center',
        padding: ['3rem 1rem', '3rem 0rem', 0, '3rem 7rem'],
        color: 'black',
        margin: ['4rem 0rem', '4rem 1rem', '4rem 3rem', '4rem', '4rem'],
      }}
    >
      <img
        src="/h1deco_left.svg"
        sx={{
          width: ['30px', '30px', 'auto'],
          height: ['135%', '135%', '50%'],
          position: 'relative',
          bottom: 50,
          display: 'flex',
          filter: 'opacity(0.5) drop-shadow(0 0 0 #ff0000)',
        }}
      />
      <div
        sx={{
          marginX: [0, '0.2rem', '1rem', '2rem'],
          padding: [0, 0, '0.5rem', '1rem'],
          fontSize: ['16px', '20px', '26px', '18px', '32px'],
          textAlign: 'center',
        }}
      >
        IT求人／エンジニア向け
        <br />
        <h1
          sx={{
            fontSize: ['1.1rem', '1.5rem', '1.9rem', '1.9rem', '3rem'],
          }}
        >
          高単価リモート案件が豊富
        </h1>
      </div>
      <img
        src="/h1deco_right.svg"
        sx={{
          width: ['30px', '30px', 'auto'],
          height: ['100%', '100%', '50%'],
          position: 'relative',
          top: ['20px', '40px', '20px'],
          display: 'flex',
          filter: 'opacity(0.5) drop-shadow(0 0 0 #ff0000)',
        }}
      />
    </div>
    {/* <div
      sx={{
        display: 'flex',
        color: 'black',
        flexDirection: 'column',
        alignItems: ['center', 'flex-start'],
      }}
    >
      <h1
        sx={{
          fontSize: ['1rem', '1.5rem', '2rem'],
          marginRight: ['150px', '150px', 0],
          marginLeft: ['0', '48px', '0'],
        }}
      >
        キーワードで案件検索
      </h1>
      <div sx={{ display: 'flex', alignItems: 'center' }}>
        <input
          sx={{
            borderRadius: '50px',
            border: '1px solid #9f9f9f',
            backGround: '#fff',
            maxHeight: '60px',
            padding: '1.5rem 3.5rem',
            boxSizing: 'border-box',
            width: ['235px', '300px', '550px', '700px'],
            fontSize: '15px',
            marginRight: ['0', '20px'],
            marginLeft: ['0', '35px', '0'],
          }}
          placeholder="検索キーワード"
        />
        <button
          sx={{
            flexDirection: 'row',
            backgroundColor: '#22426f',
            border: 'none',
            borderRadius: '50px',
            width: ['100px', '85px', '150px'],
            fontSize: ['14px', '12px', '16px'],
            cursor: 'pointer',
            display: 'flex',
            color: '#fff',
            fontWeight: 600,
            margin: ['10px', 0],
          }}
        >
          <div
            sx={{
              margin: ['10px', '0,', '15px'],
              marginRight: ['5px', '0', '15px'],
            }}
          >
            <img
              src="/searchIcon.svg"
              sx={{
                marginTop: ['10px', 0],
                width: ['70%', '70%', '100%'],
                height: ['70%', '70%', '100%'],
              }}
            />
          </div>
          <span sx={{ marginTop: ['20px', '13px', '20px'] }}>検索</span>
        </button>
      </div>
    </div>
    <div
      sx={{
        border: '1px solid #d1d1d1',
        borderRadius: '5px',
        color: 'black',
        padding: '20px',
        margin: ['35px', '35px', '60px'],
        fontSize: ['15px', '18px', '20px'],
        fontWeight: '600',
        height: ['19%', '12%', 'auto'],
        width: ['auto', '400px', 'auto'],
      }}
    >
      <img src="/popular_icon.svg" />
      <span
        sx={{
          marginRight: '100px',
          borderBottom: '1px solid  #d1d1d1',
          padding: '2px',
        }}
      >
        フリーランスエンジニアで需要の高いキーワード
      </span>
      <ul
        sx={{
          display: 'flex',
          listStyle: 'none',
          justifyContent: ['flex-start', 'center'],
          flexWrap: 'wrap',
          padding: 'inherit',
        }}
      >
        <li
          sx={{
            borderRadius: '100px',
            marginRight: '10px',
            marginBottom: ['10px', 0],
            padding: '10px',
            background: 'rgba(108, 155, 208, .14)',
          }}
        >
          <a href="#">Java</a>
        </li>
        <li
          sx={{
            borderRadius: '100px',
            marginRight: '10px',
            marginBottom: ['10px', 0],
            padding: '10px',
            background: 'rgba(108, 155, 208, .14)',
          }}
        >
          <a href="#">PHP</a>
        </li>
        <li
          sx={{
            borderRadius: '100px',
            marginRight: '10px',
            marginBottom: ['10px', 0],
            padding: '10px',
            background: 'rgba(108, 155, 208, .14)',
          }}
        >
          <a href="#" color="#3978d1">
            Python
          </a>
        </li>

        <li
          sx={{
            borderRadius: '100px',
            marginRight: '10px',
            marginBottom: ['10px', 0],
            padding: '10px',
            background: 'rgba(108, 155, 208, .14)',
          }}
        >
          <a href="#">Ruby</a>
        </li>
        <li
          sx={{
            borderRadius: '100px',
            marginRight: '10px',
            marginBottom: ['10px', 0],
            padding: '10px',
            background: 'rgba(108, 155, 208, .14)',
          }}
        >
          <a href="#">Scale</a>
        </li>
        <li
          sx={{
            borderRadius: '100px',
            marginRight: '10px',
            marginBottom: ['10px', 0],
            padding: '10px',
            background: 'rgba(108, 155, 208, .14)',
          }}
        >
          <a href="#">JavaScript</a>
        </li>
        <li
          sx={{
            borderRadius: '100px',
            marginRight: '10px',
            marginBottom: ['10px', 0],
            padding: '10px',
            background: 'rgba(108, 155, 208, .14)',
          }}
        >
          <a href="#">HTML</a>
        </li>
        <li
          sx={{
            borderRadius: '100px',
            marginRight: '10px',
            marginBottom: ['10px', 0],
            padding: '10px',
            background: 'rgba(108, 155, 208, .14)',
          }}
        >
          <a href="#">Swift</a>
        </li>
      </ul>
    </div> */}
    {/* <div
      sx={{
        width: '100%',
        height: ['100vh', '80dvh', '370px'],
        display: ['inline-block', 'inline-block', 'flex'],
        justifyContent: 'center',
        textAlign: 'center',
        color: 'black',
      }}
    >
      <div sx={{ marginRight: ['0', '0', '100px'] }}>
        <h1>スキルから探す</h1>
        <ul
          sx={{
            listStyle: 'none',
            borderRadius: '5px',
            border: '1px solid #bfbfbf',
            background: '#fff',
            padding: '15px',
            textAlign: ['center', 'center', 'left'],
            width: ['200px', '200px', '100%'],
            marginLeft: ['90px', '135px', 0],
          }}
        >
          <li>
            <input type="checkbox" />
            Android
          </li>
          <li>
            <input type="checkbox" />
            JavaScript
          </li>
          <li>
            <input type="checkbox" />
            Java
          </li>
          <li>
            <input type="checkbox" />
            Next.js
          </li>
          <li>
            <input type="checkbox" />
            React
          </li>
        </ul>
      </div>
      <div>
        <h1>職業から探す</h1>
        <ul
          sx={{
            listStyle: 'none',
            borderRadius: '5px',
            border: '1px solid #bfbfbf',
            background: '#fff',
            padding: ['8px', '15px'],
            textAlign: ['center', 'center', 'left'],
            width: ['200px', '200px', '100%'],
            marginLeft: ['90px', '135px', 0],
          }}
        >
          <li>
            <input type="checkbox" />
            フロントエンドエンジニア
          </li>
          <li>
            <input type="checkbox" />
            バックエンドエンジニア
          </li>
          <li>
            <input type="checkbox" />
            ITコンサルタント
          </li>
          <li>
            <input type="checkbox" />
            組込系エンジニア
          </li>
          <li>
            <input type="checkbox" />
            カスタマーサポート
          </li>
        </ul>
      </div>
    </div> */}
    {/* <div
      sx={{
        color: 'black',
        marginTop: '100px',
        display: 'flex',
        flexDirection: ['column', 'column', 'column', 'column', 'row'],
        alignItems: 'center',
      }}
    >
      <div sx={{ paddingRight: ['0', '0', '0', '0', '50px'] }}>
        <h1>エリア</h1>
        <button
          sx={{
            width: '300px',
            borderRadius: '5px',
            border: '1px solid #c4c8cb',
            backgroundColor: '#fff',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1.5rem',
            boxsizing: 'border-box',
            cursor: 'pointer',
            color: '#9F9F9F',
            fontSize: '20px',
          }}
        >
          <span>選択する</span>
          <img src="/plus.svg" width="16" height="16"></img>
        </button>
      </div>
      <div>
        <h1>単価</h1>
        <button
          sx={{
            width: '300px',
            borderRadius: '5px',
            border: '1px solid #c4c8cb',
            backgroundColor: '#fff',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1.5rem',
            boxsizing: 'border-box',
            cursor: 'pointer',
            color: '#9F9F9F',
            fontSize: '20px',
          }}
        >
          <span>選択する</span>
          <img src="/plus.svg" width="16" height="16"></img>
        </button>
      </div>
      <div sx={{ paddingLeft: [0, 0, 0, 0, '100px'] }}>
        <h1>こだわり</h1>
        <button
          sx={{
            width: '300px',
            borderRadius: '5px',
            border: '1px solid #c4c8cb',
            backgroundColor: '#fff',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1.5rem',
            boxsizing: 'border-box',
            cursor: 'pointer',
            color: '#9F9F9F',
            fontSize: '20px',
          }}
        >
          <span>選択する</span>
          <img src="/plus.svg" width="16" height="16"></img>
        </button>
      </div>
    </div>
    <button
      sx={{
        display: 'flex',
        width: ['300px', '300px', '300px', '300px', '400px'],
        color: '#fff',
        borderRadius: '5px',
        boxShadow: '0 5px 10px 0 rgba(0,0,0,.15)',
        border: '1px solid #133059',
        background: '#22426f',
        marginTop: '60px',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: [
          'min(4.5vw, 1.8rem)',
          'min(4.5vw, 1.8rem)',
          'min(3.5vw, 1.3rem)',
          'min(1.5vw, 1.8rem)',
        ],
        fontWeight: '600',
      }}
    >
      <img src="/searchIcon.svg" width="29" height="29"></img>
      <p sx={{ marginLeft: '10px' }}>この条件で検索する</p>
    </button> */}
  </div>
)

export default Search
