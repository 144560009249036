/** @jsx jsx */
import { jsx } from 'theme-ui'

const ReserveService = () => {
  const handleButtonClick = () => {
    open(
      'https://timerex.net/s/info_f08c_3b71/2a13158e',
      '_blank',
      'noopener,noreferrer',
    )
  }
  return (
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        color: 'white',
        fontFamily: 'Noto Sans JP, sans-serif',
      }}
    >
      <main
        sx={{
          position: 'relative',
          '@media screen and (max-width: 768px)': {
            width: '100vw',
            height: '100%',
            boxSizing: 'border-box',
          },
        }}
      >
        <img
          src="/my_pc.jpg"
          alt="my_pc.jpg"
          sx={{
            width: '100vw',
            height: ['600px', '800px', '900px'],
            objectFit: 'cover',
            objectPosition: [
              '40% center',
              '30% center',
              '30% center',
              '30% center',
              '40% center',
            ],
          }}
        />
        <div
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            bg: 'rgba(0, 0, 0, 0.3)',
          }}
        />
        <div
          sx={{
            textAlign: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
          }}
        >
          <h1
            sx={{
              textAlign: 'left',
              fontSize: ['2.5rem', '3rem', '3rem'],
              minHeight: '175px',
              marginLeft: ['2rem', '2rem', '4rem'],
              marginTop: ['2rem', '2rem', '4rem'],
              color: '#fff',
              fontWeight: 'bold',
            }}
          >
            ライフスタイルに
            <br />
            合わせた働き方
          </h1>
          <br />
          <p
            sx={{
              textAlign: 'left',
              display: 'flex',
              marginLeft: ['2rem', '2rem', '4rem'],
              color: '#fff',
              lineHeight: '1.5',
            }}
          >
            エンジビズなら
            <br />
            エンジニアのライフスタイルに
            <br />
            合わせたお仕事が見つかります。
          </p>
          <button
            onClick={handleButtonClick}
            sx={{
              backgroundColor: '#f04b4c;',
              color: 'white',
              border: 'none',
              padding: '1rem 4rem',
              fontSize: ['2rem', '1rem', '2rem'],
              fontWeight: 'bold',
              borderRadius: '8px',
              cursor: 'pointer',
              marginTop: '20px',
              marginLeft: ['2rem', '2rem', '4rem'],
              display: 'block',
            }}
          >
            今すぐ予約
          </button>

          <ul
            sx={{
              display: ['none', 'flex', 'flex'],
              listStyle: 'none',
              marginTop: [0, 0, 20],
              marginLeft: ['2rem', '2rem', '3rem'],
              flexDirection: ['column', 'row', 'row'],
              maxWidth: '900px',
            }}
          >
            <li sx={{ position: 'relative' }}>
              <img src="/star.png" alt="平均年収" sx={{ width: ['100%'] }} />
              <div
                sx={{
                  position: 'absolute',
                  top: '47%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: ['0.7rem', '1rem', '1rem'],
                  fontWeight: 'bolder',
                }}
              >
                平均年収
                <br />約<strong>940</strong>万
              </div>
            </li>
            <li sx={{ position: 'relative' }}>
              <img
                src="/star.png"
                alt="平均年収"
                sx={{
                  width: '100%',
                }}
              />
              <div
                sx={{
                  position: 'absolute',
                  top: '47%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: ['0.7rem', '1rem', '1rem'],
                  fontWeight: 'bolder',
                }}
              >
                リモート案件
                <br />
                <strong>80%</strong>以上
              </div>
            </li>
            <li sx={{ position: 'relative' }}>
              <img src="/star.png" alt="平均年収" sx={{ width: '100%' }} />
              <div
                sx={{
                  position: 'absolute',
                  top: '47%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: ['0.7rem', '1rem', '1rem'],
                  fontWeight: 'bolder',
                }}
              >
                業界最大級の
                <br />
                案件
                <br />約<strong>4000</strong>件以上
              </div>
            </li>
          </ul>
          <div
            sx={{
              display: 'flex',
              flexDirection: ['column', 'column', 'column', 'row'],
              gap: ['0.2rem', '0.2rem', '0.2rem', '2rem'],
              fontSize: '1rem',
              textAlign: 'left',
              margin: ['0', '0', '0', '0.8rem 0 1.2rem'],
              marginTop: ['2rem', '0', '0'],
              color: '#fff',
              fontWeight: 400,
            }}
          >
            <div sx={{ marginLeft: ['2rem', '2rem', '4rem'], color: '#fff' }}>
              ※1 2024年01月時点の週5稼働者支払報酬額(税込)の平均額
              <br />
              ※2 2024年01月時点の総案件におけるリモート割合
              <br />
              ※3 2024年01月時点の総案件数
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default ReserveService
